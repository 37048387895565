import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  Divider,
  SimpleGrid,
  Avatar,
  IconButton,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormHelperText,
} from "@chakra-ui/react";
import { FiCamera, FiTrash2 } from "react-icons/fi";
import axios from "axios";
import Cookies from "js-cookie";

// Placeholder for uploading and removing a logo
function LogoUpload({ logoUrl, onUpload, onRemove }) {
  return (
    <Box position="relative" w="fit-content" mx="auto" mb={4}>
      <Avatar size="xl" src={logoUrl} name="Organization Logo" />
      <IconButton
        icon={<FiCamera />}
        position="absolute"
        bottom="-2"
        right="-2"
        colorScheme="teal"
        size="sm"
        aria-label="Upload Logo"
        onClick={onUpload}
      />
      {logoUrl && (
        <IconButton
          icon={<FiTrash2 />}
          position="absolute"
          bottom="-2"
          left="-2"
          colorScheme="red"
          size="sm"
          aria-label="Remove Logo"
          onClick={onRemove}
        />
      )}
    </Box>
  );
}

function SettingsPage() {
  // Loading & error states
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);

  // Organization data
  const [logoUrl, setLogoUrl] = useState("");
  const [orgName, setOrgName] = useState("");
  const [website, setWebsite] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [address, setAddress] = useState("");

  // For the "Danger Zone" (Delete/Deactivate)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [actionLoading, setActionLoading] = useState(false);

  // Chakra UI theme-based styling
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const cardBg = useColorModeValue("white", "gray.700");
  const cardShadow = useColorModeValue("sm", "dark-lg");
  const textColor = useColorModeValue("gray.800", "gray.100");

  useEffect(() => {
    // Fetch organization settings from the server
    const fetchSettings = async () => {
      setLoading(true);
      setErrorMessage(null);
      try {
        const token = Cookies.get("AuthToken");
        // Example: Replace with your real endpoint
        // const response = await axios.get(
        //   `${process.env.REACT_APP_SERVER}/v1/organization/settings`,
        //   { headers: { Authorization: `Bearer ${token}` } }
        // );
        // const data = response.data;
        // For demo, just use static data:
        const data = {
          logoUrl: "",
          orgName: "My Organization",
          website: "https://example.org",
          contactEmail: "contact@example.org",
          phoneNumber: "+1 (555) 555-1234",
          address: "123 Main St, City, Country",
        };
        setLogoUrl(data.logoUrl);
        setOrgName(data.orgName);
        setWebsite(data.website);
        setContactEmail(data.contactEmail);
        setPhoneNumber(data.phoneNumber);
        setAddress(data.address);
      } catch (error) {
        setErrorMessage("Failed to load organization settings.");
      } finally {
        setLoading(false);
      }
    };

    fetchSettings();
  }, []);

  // Handle "Save" changes
  const handleSave = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const token = Cookies.get("AuthToken");
      // Example: Replace with your real endpoint
      // await axios.put(
      //   `${process.env.REACT_APP_SERVER}/v1/organization/settings`,
      //   { orgName, website, contactEmail, phoneNumber, address, logoUrl },
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
      // For demo, assume success
    } catch (error) {
      setErrorMessage("Failed to save changes.");
    } finally {
      setLoading(false);
    }
  };

  // Handle "Cancel" changes (re-fetch or revert to saved state)
  const handleCancel = () => {
    // Re-fetch or revert
    window.location.reload(); // naive approach: reload page
  };

  // Handle uploading a new logo
  const handleUploadLogo = () => {
    // Show a file-picker or open a modal in a real app
    alert("Open file picker to upload a new logo...");
  };

  // Handle removing the existing logo
  const handleRemoveLogo = () => {
    setLogoUrl("");
  };

  // Handle "Delete Organization" or "Deactivate Organization"
  const handleDeleteOrganization = async () => {
    setActionLoading(true);
    try {
      const token = Cookies.get("AuthToken");
      // Example: Replace with your real endpoint
      // await axios.delete(
      //   `${process.env.REACT_APP_SERVER}/v1/organization/delete`,
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
      // For demo, assume success
      onClose();
      alert("Organization deleted. Redirect or update state as needed.");
    } catch (error) {
      // handle error
    } finally {
      setActionLoading(false);
    }
  };

  // If loading, show a spinner
  if (loading) {
    return (
      <Center bg={pageBg} minH="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <Box bg={pageBg} minH="100vh" py={8} px={{ base: 4, md: 8 }}>
      <Box
        bg={cardBg}
        shadow={cardShadow}
        borderRadius="lg"
        maxW="900px"
        mx="auto"
        p={{ base: 6, md: 8 }}
      >
        <VStack align="stretch" spacing={6}>
          <Heading as="h2" size="lg" color={textColor}>
            Organization Settings
          </Heading>

          {errorMessage && (
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}

          <Divider />

          {/* Organization Profile */}
          <Heading as="h3" size="md" color={textColor}>
            Organization Profile
          </Heading>
          <Text fontSize="sm" color={textColor}>
            Update your organization's basic info and logo.
          </Text>

          {/* Logo Upload */}
          <LogoUpload
            logoUrl={logoUrl}
            onUpload={handleUploadLogo}
            onRemove={handleRemoveLogo}
          />

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl>
              <FormLabel color={textColor}>Organization Name</FormLabel>
              <Input
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
            </FormControl>

            <FormControl>
              <FormLabel color={textColor}>Website</FormLabel>
              <Input
                value={website}
                onChange={(e) => setWebsite(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          <Divider />

          {/* Contact Information */}
          <Heading as="h3" size="md" color={textColor}>
            Contact Information
          </Heading>
          <Text fontSize="sm" color={textColor}>
            How your team can be reached.
          </Text>
          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
            <FormControl>
              <FormLabel color={textColor}>Contact Email</FormLabel>
              <Input
                value={contactEmail}
                onChange={(e) => setContactEmail(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={textColor}>Phone Number</FormLabel>
              <Input
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
            </FormControl>
          </SimpleGrid>

          <FormControl>
            <FormLabel color={textColor}>Address</FormLabel>
            <Textarea
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              rows={3}
            />
          </FormControl>

          <Divider />

          {/* Action Buttons */}
          <Box textAlign="right">
            <Button mr={3} onClick={handleCancel} variant="ghost">
              Cancel
            </Button>
            <Button colorScheme="teal" onClick={handleSave} isLoading={loading}>
              Save Changes
            </Button>
          </Box>

          {/* Danger Zone */}
          <Divider />
          <Heading as="h3" size="md" color="red.400">
            Danger Zone
          </Heading>
          <Text fontSize="sm" color={textColor}>
            Delete or deactivate your entire organization. Proceed with caution!
          </Text>
          <Box textAlign="right">
            <Button colorScheme="red" variant="outline" onClick={onOpen}>
              Delete Organization
            </Button>
          </Box>
        </VStack>
      </Box>

      {/* Delete Confirmation Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Confirm Deletion</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              Deleting the organization is <strong>irreversible</strong>. Are
              you sure you want to proceed?
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose} mr={3}>
              Cancel
            </Button>
            <Button
              colorScheme="red"
              onClick={handleDeleteOrganization}
              isLoading={actionLoading}
            >
              Delete Organization
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default SettingsPage;
