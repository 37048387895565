import React, { useState } from "react";
import {
  Box,
  VStack,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  useColorModeValue,
  Center,
  Stack,
  HStack,
  Progress,
  Checkbox,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";

function CreateOrganization() {
  const [step, setStep] = useState(1); // Track the current step
  const [formData, setFormData] = useState({
    name: "",
    type: "", // "Individual" or "Corporation"
    isDeveloper: false,
    isAdvertiser: false,
    address: {
      street: "",
      city: "",
      state: "",
      postalCode: "",
      country: "", // Must be a valid 2-letter ISO code, e.g. "US"
    },
    contactInfo: {
      email: "",
      phone: "",
    },
    businessDetails: {
      businessName: "",
      taxId: "",
      representative: {
        firstName: "",
        lastName: "",
        dob: "",
        phone: "",
        email: "",
        ssnLast4: "",
        relationship: {
          title: "",
        },
      },
    },
    individualDetails: {
      firstName: "",
      lastName: "",
      dob: "",
      ssnLast4: "",
    },
  });

  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const navigate = useNavigate();

  const bgColor = useColorModeValue("white", "gray.800");
  const inputBgColor = useColorModeValue("gray.100", "gray.700");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  // Handle text/input changes (including nested fields)
  const handleInputChange = (e) => {
    const { name, value, type: inputType, checked } = e.target;

    // For checkboxes:
    if (inputType === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked,
      }));
      return;
    }

    // For dot notation like "address.street"
    if (name.includes(".")) {
      const keys = name.split(".");
      setFormData((prev) => {
        let updatedData = { ...prev };
        keys.reduce((nested, key, index) => {
          if (index === keys.length - 1) {
            nested[key] = value;
          }
          return nested[key];
        }, updatedData);
        return updatedData;
      });
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const token = Cookies.get("AuthToken");

    try {
      // POST data that matches your backend’s shape
      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/organization/create`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Organization Created",
        description: "Your organization has been created successfully.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });

      navigate("/organizations");
    } catch (error) {
      console.error("Error creating organization:", error);
      toast({
        title: "Error",
        description:
          error.response?.data?.message || "An unexpected error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  // Navigation Between Steps
  const nextStep = () => setStep((prev) => prev + 1);
  const prevStep = () => setStep((prev) => prev - 1);

  return (
    <Center minH="100vh" bg={useColorModeValue("gray.100", "gray.900")}>
      <Box
        maxW="800px"
        w="full"
        p={8}
        borderWidth={1}
        borderRadius="md"
        boxShadow="lg"
        bg={bgColor}
        borderColor={borderColor}
      >
        {/* Progress bar showing which step we're on */}
        <Progress value={(step / 3) * 100} mb={6} />

        <Heading as="h1" size="lg" textAlign="center" mb={6}>
          Create New Organization
        </Heading>

        <form onSubmit={handleSubmit}>
          {/* STEP 1 */}
          {step === 1 && (
            <VStack spacing={6} align="stretch">
              {/* Basic Info */}
              <FormControl id="name" isRequired>
                <FormLabel>Organization Name</FormLabel>
                <Input
                  type="text"
                  name="name"
                  placeholder="Enter organization name"
                  bg={inputBgColor}
                  value={formData.name}
                  onChange={handleInputChange}
                />
              </FormControl>

              <FormControl id="type" isRequired>
                <FormLabel>Organization Type</FormLabel>
                <Select
                  name="type"
                  placeholder="Select organization type"
                  bg={inputBgColor}
                  value={formData.type}
                  onChange={handleInputChange}
                >
                  <option value="Corporation">Corporation</option>
                  <option value="Individual">Individual</option>
                </Select>
              </FormControl>

              {/* Developer & Advertiser checkboxes */}
              <HStack>
                <Checkbox
                  name="isDeveloper"
                  isChecked={formData.isDeveloper}
                  onChange={handleInputChange}
                >
                  Developer
                </Checkbox>
                <Checkbox
                  name="isAdvertiser"
                  isChecked={formData.isAdvertiser}
                  onChange={handleInputChange}
                >
                  Advertiser
                </Checkbox>
              </HStack>

              {/* Address */}
              <FormControl id="address.street" isRequired>
                <FormLabel>Street</FormLabel>
                <Input
                  type="text"
                  name="address.street"
                  placeholder="123 Main St"
                  bg={inputBgColor}
                  value={formData.address.street}
                  onChange={handleInputChange}
                />
              </FormControl>
              <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                <FormControl id="address.city" isRequired>
                  <FormLabel>City</FormLabel>
                  <Input
                    type="text"
                    name="address.city"
                    placeholder="City"
                    bg={inputBgColor}
                    value={formData.address.city}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl id="address.state" isRequired>
                  <FormLabel>State</FormLabel>
                  <Input
                    type="text"
                    name="address.state"
                    placeholder="State"
                    bg={inputBgColor}
                    value={formData.address.state}
                    onChange={handleInputChange}
                  />
                </FormControl>
              </Stack>
              <Stack direction={{ base: "column", md: "row" }} spacing={4}>
                <FormControl id="address.postalCode" isRequired>
                  <FormLabel>Postal Code</FormLabel>
                  <Input
                    type="text"
                    name="address.postalCode"
                    placeholder="Postal Code"
                    bg={inputBgColor}
                    value={formData.address.postalCode}
                    onChange={handleInputChange}
                  />
                </FormControl>
                <FormControl id="address.country" isRequired>
                  <FormLabel>Country (2-letter ISO code)</FormLabel>
                  <Select
                    name="address.country"
                    placeholder="Select country"
                    bg={inputBgColor}
                    value={formData.address.country}
                    onChange={handleInputChange}
                  >
                    <option value="AF">Afghanistan (AF)</option>
                    <option value="AL">Albania (AL)</option>
                    <option value="AR">Argentina (AR)</option>
                    <option value="AU">Australia (AU)</option>
                    <option value="AT">Austria (AT)</option>
                    <option value="BE">Belgium (BE)</option>
                    <option value="BR">Brazil (BR)</option>
                    <option value="BG">Bulgaria (BG)</option>
                    <option value="CA">Canada (CA)</option>
                    <option value="CL">Chile (CL)</option>
                    <option value="CN">China (CN)</option>
                    <option value="CO">Colombia (CO)</option>
                    <option value="CR">Costa Rica (CR)</option>
                    <option value="CZ">Czech Republic (CZ)</option>
                    <option value="DK">Denmark (DK)</option>
                    <option value="DO">Dominican Republic (DO)</option>
                    <option value="EC">Ecuador (EC)</option>
                    <option value="EE">Estonia (EE)</option>
                    <option value="FI">Finland (FI)</option>
                    <option value="FR">France (FR)</option>
                    <option value="DE">Germany (DE)</option>
                    <option value="GR">Greece (GR)</option>
                    <option value="HK">Hong Kong (HK)</option>
                    <option value="HU">Hungary (HU)</option>
                    <option value="IS">Iceland (IS)</option>
                    <option value="IN">India (IN)</option>
                    <option value="IE">Ireland (IE)</option>
                    <option value="IL">Israel (IL)</option>
                    <option value="IT">Italy (IT)</option>
                    <option value="JP">Japan (JP)</option>
                    <option value="LV">Latvia (LV)</option>
                    <option value="LT">Lithuania (LT)</option>
                    <option value="LU">Luxembourg (LU)</option>
                    <option value="MT">Malta (MT)</option>
                    <option value="MX">Mexico (MX)</option>
                    <option value="NL">Netherlands (NL)</option>
                    <option value="NZ">New Zealand (NZ)</option>
                    <option value="NO">Norway (NO)</option>
                    <option value="PE">Peru (PE)</option>
                    <option value="PH">Philippines (PH)</option>
                    <option value="PL">Poland (PL)</option>
                    <option value="PT">Portugal (PT)</option>
                    <option value="RO">Romania (RO)</option>
                    <option value="SG">Singapore (SG)</option>
                    <option value="SK">Slovakia (SK)</option>
                    <option value="SI">Slovenia (SI)</option>
                    <option value="ZA">South Africa (ZA)</option>
                    <option value="ES">Spain (ES)</option>
                    <option value="SE">Sweden (SE)</option>
                    <option value="CH">Switzerland (CH)</option>
                    <option value="TR">Turkey (TR)</option>
                    <option value="AE">United Arab Emirates (AE)</option>
                    <option value="GB">United Kingdom (GB)</option>
                    <option value="US">United States (US)</option>
                    <option value="UY">Uruguay (UY)</option>
                    <option value="VE">Venezuela (VE)</option>
                    <option value="VN">Vietnam (VN)</option>
                  </Select>
                </FormControl>
              </Stack>

              {/* Next Step Button */}
              <Button colorScheme="teal" width="full" onClick={nextStep}>
                Next
              </Button>
            </VStack>
          )}

          {/* STEP 2 */}
          {step === 2 && (
            <VStack spacing={6} align="stretch">
              {/* Contact Info */}
              <FormControl id="contactInfo.email" isRequired>
                <FormLabel>Email</FormLabel>
                <Input
                  type="email"
                  name="contactInfo.email"
                  placeholder="contact@example.com"
                  bg={inputBgColor}
                  value={formData.contactInfo.email}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl id="contactInfo.phone">
                <FormLabel>Phone</FormLabel>
                <Input
                  type="tel"
                  name="contactInfo.phone"
                  placeholder="+1234567890"
                  bg={inputBgColor}
                  value={formData.contactInfo.phone}
                  onChange={handleInputChange}
                />
              </FormControl>

              {/* Corporation Details */}
              {formData.type === "Corporation" && (
                <>
                  <Heading as="h2" size="md" mt={4}>
                    Business Details
                  </Heading>
                  <FormControl id="businessDetails.businessName" isRequired>
                    <FormLabel>Business Name</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.businessName"
                      placeholder="Business Name"
                      bg={inputBgColor}
                      value={formData.businessDetails.businessName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl id="businessDetails.taxId" isRequired>
                    <FormLabel>Tax ID</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.taxId"
                      placeholder="Tax ID"
                      bg={inputBgColor}
                      value={formData.businessDetails.taxId}
                      onChange={handleInputChange}
                    />
                  </FormControl>

                  {/* Representative Subsection */}
                  <Heading as="h3" size="sm" mt={4}>
                    Company Representative
                  </Heading>
                  <FormControl isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.representative.firstName"
                      placeholder="Michael"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.firstName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.representative.lastName"
                      placeholder="Santamaria"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.lastName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl isRequired>
                    <FormLabel>Date of Birth</FormLabel>
                    <Input
                      type="date"
                      name="businessDetails.representative.dob"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.dob}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Phone</FormLabel>
                    <Input
                      type="tel"
                      name="businessDetails.representative.phone"
                      placeholder="+1234567890"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.phone}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Email</FormLabel>
                    <Input
                      type="email"
                      name="businessDetails.representative.email"
                      placeholder="michael.santamaria@nexxy.com"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.email}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>SSN Last 4</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.representative.ssnLast4"
                      placeholder="1234"
                      bg={inputBgColor}
                      value={formData.businessDetails.representative.ssnLast4}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>Representative Title</FormLabel>
                    <Input
                      type="text"
                      name="businessDetails.representative.relationship.title"
                      placeholder="CEO"
                      bg={inputBgColor}
                      value={
                        formData.businessDetails.representative.relationship
                          .title
                      }
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </>
              )}

              {/* Individual Details */}
              {formData.type === "Individual" && (
                <>
                  <Heading as="h2" size="md" mt={4}>
                    Personal Details
                  </Heading>
                  <FormControl id="individualDetails.firstName" isRequired>
                    <FormLabel>First Name</FormLabel>
                    <Input
                      type="text"
                      name="individualDetails.firstName"
                      placeholder="John"
                      bg={inputBgColor}
                      value={formData.individualDetails.firstName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl id="individualDetails.lastName" isRequired>
                    <FormLabel>Last Name</FormLabel>
                    <Input
                      type="text"
                      name="individualDetails.lastName"
                      placeholder="Doe"
                      bg={inputBgColor}
                      value={formData.individualDetails.lastName}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl id="individualDetails.dob" isRequired>
                    <FormLabel>Date of Birth</FormLabel>
                    <Input
                      type="date"
                      name="individualDetails.dob"
                      bg={inputBgColor}
                      value={formData.individualDetails.dob}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>SSN Last 4</FormLabel>
                    <Input
                      type="text"
                      name="individualDetails.ssnLast4"
                      placeholder="1234"
                      bg={inputBgColor}
                      value={formData.individualDetails.ssnLast4}
                      onChange={handleInputChange}
                    />
                  </FormControl>
                </>
              )}

              <HStack>
                <Button colorScheme="gray" onClick={prevStep}>
                  Back
                </Button>
                <Button colorScheme="teal" onClick={nextStep}>
                  Next
                </Button>
              </HStack>
            </VStack>
          )}

          {/* STEP 3 */}
          {step === 3 && (
            <VStack spacing={6} align="stretch">
              {/* Verification & Submit */}
              <Heading as="h2" size="md">
                Verify Your Details
              </Heading>
              <Box>
                <strong>Organization Name:</strong> {formData.name}
                <br />
                <strong>Type:</strong> {formData.type}
                <br />
                <strong>Developer?:</strong>{" "}
                {formData.isDeveloper ? "Yes" : "No"}
                <br />
                <strong>Advertiser?:</strong>{" "}
                {formData.isAdvertiser ? "Yes" : "No"}
                <br />
                <strong>Address:</strong> {formData.address.street},{" "}
                {formData.address.city}, {formData.address.state},{" "}
                {formData.address.postalCode}, {formData.address.country}
                <br />
                <strong>Email:</strong> {formData.contactInfo.email}
                <br />
                <strong>Phone:</strong> {formData.contactInfo.phone || "N/A"}
              </Box>
              <HStack>
                <Button colorScheme="gray" onClick={prevStep}>
                  Back
                </Button>
                <Button
                  colorScheme="teal"
                  type="submit"
                  isLoading={loading}
                  width="full"
                >
                  Create Organization
                </Button>
              </HStack>
            </VStack>
          )}
        </form>
      </Box>
    </Center>
  );
}

export default CreateOrganization;
