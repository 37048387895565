import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Center,
  Spinner,
  Divider,
  Button,
  HStack,
  useColorModeValue,
  SimpleGrid,
  Badge,
  Icon,
} from "@chakra-ui/react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FiPlus } from "react-icons/fi";

function AppDashboard() {
  const { organizationId } = useParams();
  const navigate = useNavigate();
  const [apps, setApps] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Theme-specific colors
  const bgColor = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const metaColor = useColorModeValue("gray.600", "gray.400");
  const cardShadow = useColorModeValue("lg", "dark-lg");

  const fetchApps = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = Cookies.get("AuthToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/v1/organization/${organizationId}/apps`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setApps(response.data.apps);
    } catch (error) {
      if (error.response?.status === 404) {
        setLoading(false);
      } else {
        setError("Failed to fetch apps. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApps();
  }, [organizationId]);

  if (loading) {
    return (
      <Center minH="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <VStack spacing={4}>
          <Heading size="lg" color="red.500">
            Error
          </Heading>
          <Text color={metaColor}>{error}</Text>
          <Button
            colorScheme="teal"
            leftIcon={<FiPlus />}
            onClick={() =>
              navigate(`/organization/${organizationId}/create-app`)
            }
          >
            Create App
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Box p={{ base: 4, md: 6 }}>
      <Box
        minH="500px"
        mx="auto"
        bg={bgColor}
        borderRadius="lg"
        shadow={cardShadow}
        p={{ base: 4, md: 8 }}
      >
        <HStack
          justify="space-between"
          mb={6}
          flexWrap="wrap"
          spacing={{ base: 2, md: 4 }}
        >
          <Heading as="h1" size={{ base: "md", md: "lg" }} color={textColor}>
            Apps Dashboard
          </Heading>
          <Button
            colorScheme="teal"
            size={{ base: "sm", md: "md" }}
            leftIcon={<FiPlus />}
            onClick={() =>
              navigate(`/organization/${organizationId}/create-app`)
            }
          >
            Create New App
          </Button>
        </HStack>

        <Divider mb={6} />

        {apps.length === 0 ? (
          <Center>
            <VStack spacing={4}>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                color={metaColor}
                mt={50}
              >
                No apps found. Start by creating a new app!
              </Text>
            </VStack>
          </Center>
        ) : (
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            spacing={{ base: 4, md: 6 }}
          >
            {apps.map((app) => (
              <Box
                key={app.id}
                p={{ base: 4, md: 5 }}
                bg={sectionBg}
                borderRadius="md"
                shadow="sm"
                _hover={{ shadow: "lg" }}
                transition="all 0.2s"
              >
                <VStack align="start" spacing={2}>
                  <Heading
                    as="h3"
                    size={{ base: "sm", md: "md" }}
                    color={textColor}
                  >
                    {app.name}
                  </Heading>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color={metaColor}
                    noOfLines={2}
                  >
                    {app.description || "No description available"}
                  </Text>
                  <HStack>
                    <Badge
                      colorScheme={
                        app.status.toLowerCase() === "active"
                          ? "green"
                          : "yellow"
                      }
                    >
                      {app.status}
                    </Badge>
                    <Text fontSize={{ base: "xs", md: "sm" }} color={metaColor}>
                      Platform: {app.platform || "N/A"}
                    </Text>
                  </HStack>
                  <Button
                    colorScheme="teal"
                    size="sm"
                    mt={3}
                    onClick={() => navigate(`/app/${app.id}`)}
                  >
                    View App
                  </Button>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>
    </Box>
  );
}

export default AppDashboard;
