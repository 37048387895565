import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import Navbar from "./Navbar";
import DashboardNavbar from "./dashboardNav";
import Footer from "./footer";
import "../App.css"; // Ensure the CSS for layout is imported
import {
  Box,
  useBreakpointValue,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

export const PublicLayout = () => {
  const bg = useColorModeValue("gray.50", "gray.900");
  const text = useColorModeValue("gray.800", "gray.200");

  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="100vh"
      bg={bg}
      color={text}
    >
      <Navbar />
      <Box flex="1" p={6}>
        <Outlet />
      </Box>
      <Footer />
    </Box>
  );
};
export const PrivateLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  // Determine if sidebar should be visible on large screens
  const isSidebarVisible = useBreakpointValue({ base: false, lg: true });

  const bg = useColorModeValue("gray.50", "gray.900");
  const sidebarBg = useColorModeValue("gray.100", "gray.800");
  const footerBg = useColorModeValue("gray.100", "gray.800");
  const text = useColorModeValue("gray.800", "gray.200");

  return (
    <Box
      display="flex"
      flexDirection="column"
      minH="100vh"
      bg={bg}
      color={text}
    >
      <DashboardNavbar />
      <Box
        flex="1"
        ml={{ base: 0, lg: "250px" }}
        transition="margin-left 0.3s ease"
        p={6}
        mt={20}
      >
        <Outlet />
      </Box>
      <Box
        ml={{ base: 0, lg: "250px" }}
        bg={footerBg}
        color={text}
        textAlign="center"
        mt="auto"
      >
        <Footer />
      </Box>
    </Box>
  );
};
