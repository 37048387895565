import React, { useState } from "react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import {
  Box,
  Flex,
  HStack,
  VStack,
  Link,
  Text,
  Spacer,
  IconButton,
  Avatar,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useColorMode,
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  List,
  ListItem,
  Divider,
  useDisclosure,
  useBreakpointValue,
  Icon,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, HamburgerIcon } from "@chakra-ui/icons";
import {
  FaHome,
  FaChartBar,
  FaUserShield,
  FaDatabase,
  FaBolt,
  FaFolder,
  FaRegCommentDots,
  FaCog,
  FaUsers,
  FaColumns,
  FaTh,
  FaMobileAlt,
  FaThList,
  FaMoneyBill,
} from "react-icons/fa";
import Cookies from "js-cookie";

function DashboardNavbar() {
  const { colorMode, toggleColorMode } = useColorMode();
  const navigate = useNavigate();
  const location = useLocation(); // <-- Hook to get current path
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [organization, setOrganization] = useState("Nexxy Console");
  const isOrganizationPath = location.pathname.includes("/organization/");
  const { organizationId } = useParams();

  // If using "lg" or bigger, sidebar is always visible
  const isSidebarVisible = useBreakpointValue({ base: false, lg: true });

  // Check if path includes "/organization/" to decide what to display
  const isOrgRoute = location.pathname.includes("/organization/");

  const handleLogout = () => {
    // ... your logout logic
    const token = Cookies.remove("AuthToken");

    navigate("/login");
  };

  /////

  // Define your nav items here
  // --------- Define two (or more) sets of nav items --------- //
  const orgNavItems = [
    // If the user is on /organization/..., show these
    {
      label: "Dashboard",
      icon: FaThList,
      to: `/organization/${organizationId}`,
    },
    {
      label: "Apps",
      icon: FaMobileAlt,
      to: `/organization/${organizationId}/apps`,
    },
    {
      label: "Campaigns",
      icon: FaTh,
      to: `/organization/${organizationId}/campaigns`,
    },
    {
      label: "Members",
      icon: FaUsers,
      to: `/organization/${organizationId}/members`,
    },
    {
      label: "Billing",
      icon: FaMoneyBill,
      to: `/organization/${organizationId}/billing`,
    },
    {
      label: "Settings",
      icon: FaCog,
      to: `/organization/${organizationId}/settings`,
    },
  ];

  const defaultNavItems = [
    // If the user is on /dashboard, show these instead
    { label: "Home", icon: FaHome, to: "/" },
    { label: "Analytics", icon: FaChartBar, to: "/analytics" },
    // Add any other default routes here
  ];

  // Basic color styling
  const bg = colorMode === "light" ? "gray.100" : "gray.900";
  const hoverBg = colorMode === "light" ? "gray.200" : "gray.700";
  const activeBg = colorMode === "light" ? "gray.300" : "gray.700";
  const borderColor = colorMode === "light" ? "gray.200" : "gray.700";
  const iconColor = "teal.500";

  // Render the nav items, highlighting the active link
  const renderNavItems = (closeOnClick = false) =>
    navItems.map((item) => {
      // If the browser path = item.to, highlight it
      const isActive = location.pathname === item.to;
      return (
        <Box
          key={item.label}
          as={RouterLink}
          to={item.to}
          display="flex"
          alignItems="center"
          px={3}
          py={2}
          borderRadius="md"
          cursor="pointer"
          bg={isActive ? activeBg : "transparent"}
          _hover={{ bg: hoverBg }}
          onClick={closeOnClick ? onClose : undefined}
        >
          <Icon as={item.icon} fontSize="lg" mr={3} color={iconColor} />
          <Text
            fontSize="md"
            fontWeight={isActive ? "semibold" : "normal"}
            color={isActive ? iconColor : undefined}
          >
            {item.label}
          </Text>
        </Box>
      );
    });
  const navItems = isOrganizationPath ? orgNavItems : defaultNavItems;

  return (
    <>
      {/* Navbar */}
      <Box
        as="nav"
        bg={colorMode === "light" ? "white" : "gray.800"}
        borderBottomWidth={1}
        borderBottomColor={colorMode === "light" ? "gray.200" : "gray.700"}
        py={4}
        shadow="md"
        width="100%"
        zIndex={50}
        position="fixed"
      >
        <Flex maxW="1200px" mx="auto" align="center" px={6}>
          {/* Hamburger Icon for Sidebar (only on small screens) */}
          {!isSidebarVisible && (
            <IconButton
              icon={<HamburgerIcon />}
              variant="ghost"
              aria-label="Open menu"
              onClick={onOpen}
            />
          )}

          <Text fontWeight="bold" fontSize="2xl" ml={!isSidebarVisible ? 4 : 0}>
            Nexxy
          </Text>

          <Spacer />

          {/* Navbar Links */}
          <HStack spacing={8} display={{ base: "none", md: "flex" }}>
            {/* Example: always visible in top nav */}
            <Link
              as={RouterLink}
              to="/dashboard"
              fontWeight="bold"
              color={colorMode === "light" ? "gray.700" : "gray.300"}
            >
              Dashboard
            </Link>
            {/* If you want these top nav items visible only on certain routes, 
                you can also apply the same pattern here. */}
          </HStack>

          {/* Theme Toggle and User Actions */}
          <HStack spacing={4}>
            <IconButton
              onClick={toggleColorMode}
              aria-label="Toggle color mode"
              icon={colorMode === "light" ? <MoonIcon /> : <SunIcon />}
              variant="ghost"
            />
            <Menu>
              <MenuButton as={Button} variant="link" cursor="pointer">
                <Avatar size="sm" name="User" bg="teal.500" />
              </MenuButton>
              <MenuList
                bg={colorMode === "light" ? "white" : "gray.700"}
                borderColor={colorMode === "light" ? "gray.200" : "gray.600"}
              >
                <MenuItem as={RouterLink} to="/profile">
                  Profile
                </MenuItem>
                <MenuItem as={RouterLink} to="/settings">
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout} color="red.500">
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>

      {/* Sidebar for large screens */}
      {isSidebarVisible ? (
        <Box
          as="aside"
          bg={bg}
          w="250px"
          h="100vh"
          position="fixed"
          top="0"
          left="0"
          zIndex={40}
          py={6}
          px={4}
          borderRightWidth="1px"
          borderRightColor={borderColor}
          display="flex"
          flexDirection="column"
          justifyContent="flex-start"
        >
          {/* Organization / Logo */}
          <Text fontSize="xl" fontWeight="bold" color={iconColor} mb={4}>
            {organization}
          </Text>
          <Divider mb={4} />

          {/* Navigation Links */}
          <VStack align="start" spacing={5}>
            {renderNavItems()}
          </VStack>
        </Box>
      ) : (
        /* Drawer for small screens */
        <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent>
            <DrawerCloseButton />
            <DrawerHeader
              fontSize="xl"
              fontWeight="bold"
              color={iconColor}
              borderBottomWidth="1px"
              borderBottomColor={borderColor}
            >
              {organization}
            </DrawerHeader>
            <DrawerBody>
              <VStack align="start" spacing={3} mt={4}>
                {renderNavItems(true)}
                {/* Passing 'true' so clicking a link closes the drawer */}
              </VStack>
            </DrawerBody>
          </DrawerContent>
        </Drawer>
      )}
    </>
  );
}

export default DashboardNavbar;
