import React, { useState, useEffect } from "react";
import {
  Box,
  Flex,
  Grid,
  SimpleGrid,
  VStack,
  HStack,
  Heading,
  Text,
  Button,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  Divider,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Select,
  useColorModeValue,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Badge,
} from "@chakra-ui/react";
import Cookies from "js-cookie";
import axios from "axios";

// Placeholder Chart Component
function PlaceholderChart({ title }) {
  const chartBg = useColorModeValue("gray.100", "gray.700");
  return (
    <Box
      bg={chartBg}
      borderRadius="md"
      p={4}
      height="200px"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Text fontSize="sm" color="gray.500">
        {title} (Chart Placeholder)
      </Text>
    </Box>
  );
}

// Example data for Ads Usage & Payout
const SAMPLE_AD_USAGE = [
  { id: 1, name: "Banner Ad 1", cost: 1011.0 },
  { id: 2, name: "Sidebar Ad", cost: 5.0 },
];

const SAMPLE_APP_PAYOUTS = [
  { id: 1, name: "My Cool App", revenue: 22220.53 },
  { id: 2, name: "Another App", revenue: 30.0 },
];

function BillingPage() {
  // State for loading & error
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Data
  const [adsUsage, setAdsUsage] = useState([]);
  const [appsPayout, setAppsPayout] = useState([]);

  // Payment Info (for demonstration)
  const [paymentMethod, setPaymentMethod] = useState("Credit Card - **** 1234");
  const [payoutAccount, setPayoutAccount] = useState(
    "Bank Account - **** 5678"
  );

  // Payment Info Modal
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [savingInfo, setSavingInfo] = useState(false);

  // Report period selection (Weekly or Monthly)
  const [reportPeriod, setReportPeriod] = useState("weekly");

  // Chakra UI theme-based styling
  const pageBg = useColorModeValue("gray.50", "gray.800");
  const cardBg = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const secondaryTextColor = useColorModeValue("gray.500", "gray.400");
  const cardShadow = useColorModeValue("sm", "dark-lg");

  // Simulate fetching billing details
  const fetchBillingDetails = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const token = Cookies.get("AuthToken");

      // Example (replace with real calls):
      // const adsResponse = await axios.get(`${process.env.REACT_APP_SERVER}/v1/billing/ads`, {
      //   headers: { Authorization: `Bearer ${token}` },
      // });
      // const appsResponse = await axios.get(`${process.env.REACT_APP_SERVER}/v1/billing/apps`, {
      //   headers: { Authorization: `Bearer ${token}` },
      // });

      // We'll just use sample data:
      setAdsUsage(SAMPLE_AD_USAGE);
      setAppsPayout(SAMPLE_APP_PAYOUTS);
    } catch (error) {
      setErrorMessage("Failed to retrieve billing details.");
    } finally {
      setLoading(false);
    }
  };

  // On mount, load data
  useEffect(() => {
    fetchBillingDetails();
  }, []);

  // Net total = Ads charges - App revenue
  const adsCost = adsUsage.reduce((sum, ad) => sum + ad.cost, 0);
  const appsRevenue = appsPayout.reduce((sum, app) => sum + app.revenue, 0);
  const netTotal = adsCost - appsRevenue;
  const isOwedMoney = netTotal < 0;

  // Save payment info (modal logic)
  const handleSavePaymentInfo = async () => {
    setSavingInfo(true);
    try {
      const token = Cookies.get("AuthToken");
      // e.g.:
      // await axios.post(
      //   `${process.env.REACT_APP_SERVER}/v1/billing/payment-info`,
      //   { paymentMethod, payoutAccount },
      //   { headers: { Authorization: `Bearer ${token}` } }
      // );
      onClose();
    } catch (error) {
      // handle error
    } finally {
      setSavingInfo(false);
    }
  };

  return (
    <Box minH="100vh" bg={pageBg} py={6} px={{ base: 4, md: 8 }}>
      {/* Header / Title */}
      <Heading color={textColor} mb={6}>
        Billing Dashboard
      </Heading>

      {/* Error Alert */}
      {errorMessage && (
        <Alert status="error" borderRadius="md" mb={6}>
          <AlertIcon />
          {errorMessage}
        </Alert>
      )}

      {/* Top Stats + Chart Section */}
      <Grid
        templateColumns={{ base: "1fr", lg: "2fr 1fr" }}
        gap={6}
        mb={6}
        alignItems="start"
      >
        {/* Left Column: Summary Stats & Large Chart */}
        <Box bg={cardBg} shadow={cardShadow} p={6} borderRadius="lg">
          <VStack align="stretch" spacing={4}>
            {/* Stats Row */}
            <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
              <Box>
                <Text fontSize="sm" color={secondaryTextColor}>
                  Revenue
                </Text>
                <Heading size="md" color={textColor}>
                  $12,300
                </Heading>
                <Text color="green.400" fontSize="sm">
                  +15% from last week
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" color={secondaryTextColor}>
                  Ad Charges
                </Text>
                <Heading size="md" color={textColor}>
                  $3,200
                </Heading>
                <Text color="red.400" fontSize="sm">
                  -5% from last week
                </Text>
              </Box>
              <Box>
                <Text fontSize="sm" color={secondaryTextColor}>
                  App Payout
                </Text>
                <Heading size="md" color={textColor}>
                  $1,500
                </Heading>
                <Text color="green.400" fontSize="sm">
                  +8% from last week
                </Text>
              </Box>
            </SimpleGrid>

            <Divider />

            {/* Line Chart Placeholder */}
            <PlaceholderChart
              title={`${
                reportPeriod === "weekly" ? "Weekly" : "Monthly"
              } Revenue Chart`}
            />
          </VStack>
        </Box>

        {/* Right Column: Net Revenue & Additional Stats */}
        <VStack
          bg={cardBg}
          shadow={cardShadow}
          p={6}
          borderRadius="lg"
          spacing={4}
          align="stretch"
        >
          <Box>
            <Text fontSize="sm" color={secondaryTextColor} mb={1}>
              Net Total
            </Text>
            <Heading size="lg" color={isOwedMoney ? "green.400" : "red.400"}>
              {isOwedMoney
                ? `-$${Math.abs(netTotal).toFixed(2)}`
                : `$${netTotal.toFixed(2)}`}
            </Heading>
            <Text fontSize="xs" color={secondaryTextColor}>
              {isOwedMoney
                ? "Owed to you on next payout"
                : "You owe this amount for Ads"}
            </Text>
          </Box>

          <Divider />

          <VStack align="stretch" spacing={2}>
            <Text fontSize="sm" color={secondaryTextColor}>
              Payment & Payout Schedule
            </Text>
            <Text fontSize="xs" color={textColor}>
              <strong>Ads Billed:</strong> Every Friday
            </Text>
            <Text fontSize="xs" color={textColor}>
              <strong>Payouts:</strong> 1st of each month
            </Text>
          </VStack>

          <Divider />

          <VStack align="stretch" spacing={2}>
            <Text fontSize="sm" color={secondaryTextColor}>
              Payment Details
            </Text>
            <Text fontSize="sm" color={textColor}>
              <strong>Payment Method:</strong> {paymentMethod}
            </Text>
            <Text fontSize="sm" color={textColor}>
              <strong>Payout Account:</strong> {payoutAccount}
            </Text>
            <Button size="sm" colorScheme="teal" onClick={onOpen}>
              Manage Payment Info
            </Button>
          </VStack>
        </VStack>
      </Grid>

      {/* Report Controls */}
      <Flex justify="space-between" mb={4}>
        <Heading size="md" color={textColor}>
          Detailed Reports
        </Heading>
        <HStack spacing={2}>
          <Text fontSize="sm" color={secondaryTextColor}>
            View Period:
          </Text>
          <Select
            size="sm"
            value={reportPeriod}
            onChange={(e) => setReportPeriod(e.target.value)}
            width="100px"
          >
            <option value="weekly">Weekly</option>
            <option value="monthly">Monthly</option>
          </Select>
        </HStack>
      </Flex>

      {/* Ads Usage & App Payout (Tables) */}
      <SimpleGrid columns={{ base: 1, lg: 2 }} gap={6}>
        {/* Ads Usage */}
        <Box bg={cardBg} shadow={cardShadow} p={6} borderRadius="lg">
          <Heading size="sm" mb={4} color={textColor}>
            Ads Usage
          </Heading>
          {loading ? (
            <Center>
              <Spinner />
            </Center>
          ) : adsUsage.length === 0 ? (
            <Text color={textColor} fontSize="sm">
              No ad usage found.
            </Text>
          ) : (
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>Ad Name</Th>
                  <Th isNumeric>Cost (USD)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {adsUsage.map((ad) => (
                  <Tr key={ad.id}>
                    <Td>{ad.name}</Td>
                    <Td isNumeric>${ad.cost.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>

        {/* Apps Payout */}
        <Box bg={cardBg} shadow={cardShadow} p={6} borderRadius="lg">
          <Heading size="sm" mb={4} color={textColor}>
            Apps Payout
          </Heading>
          {loading ? (
            <Center>
              <Spinner />
            </Center>
          ) : appsPayout.length === 0 ? (
            <Text color={textColor} fontSize="sm">
              No apps found.
            </Text>
          ) : (
            <Table variant="simple" size="sm">
              <Thead>
                <Tr>
                  <Th>App Name</Th>
                  <Th isNumeric>Revenue (USD)</Th>
                </Tr>
              </Thead>
              <Tbody>
                {appsPayout.map((app) => (
                  <Tr key={app.id}>
                    <Td>{app.name}</Td>
                    <Td isNumeric>${app.revenue.toFixed(2)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          )}
        </Box>
      </SimpleGrid>

      {/* Manage Payment Info Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Payment Info</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl mb={4}>
              <FormLabel>Payment Method</FormLabel>
              <Input
                placeholder="e.g., Credit Card - **** 1234"
                value={paymentMethod}
                onChange={(e) => setPaymentMethod(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Payout Account</FormLabel>
              <Input
                placeholder="e.g., Bank Account - **** 5678"
                value={payoutAccount}
                onChange={(e) => setPayoutAccount(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              isLoading={savingInfo}
              onClick={handleSavePaymentInfo}
            >
              Save
            </Button>
            <Button variant="ghost" ml={3} onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default BillingPage;
