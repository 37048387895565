import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Center,
  Spinner,
  Divider,
  Button,
  HStack,
  useColorModeValue,
  SimpleGrid,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormControl,
  FormLabel,
  Input,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import {
  Link as RouterLink,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FiPlus } from "react-icons/fi";

function CampaignDashboard() {
  const { organizationId } = useParams();
  const navigate = useNavigate();

  const [campaigns, setCampaigns] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Modal for creating a campaign
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Fields for creating a campaign
  const [name, setName] = useState("");
  const [dailyBudget, setDailyBudget] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // State to show success or error messages inside the modal
  const [createError, setCreateError] = useState(null);
  const [creating, setCreating] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Theme-specific colors
  const bgColor = useColorModeValue("white", "gray.800");
  const sectionBg = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const metaColor = useColorModeValue("gray.600", "gray.400");
  const cardShadow = useColorModeValue("lg", "dark-lg");

  const fetchCampaigns = async () => {
    setLoading(true);
    setError(null);
    try {
      const token = Cookies.get("AuthToken");
      // Use POST instead of GET
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/campaign/fetchCampaign`,
        {
          organizationId: organizationId, // in the body
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setCampaigns(response.data.campaigns);
    } catch (err) {
      console.log(err);
      if (err.response?.status === 404) {
        setCampaigns([]);
      } else {
        setError("Failed to fetch campaigns. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCampaigns();
  }, [organizationId]);

  // Create campaign handler
  const handleCreateCampaign = async () => {
    setCreateError(null);
    setSuccessMessage("");
    setCreating(true);

    try {
      const token = Cookies.get("AuthToken");
      // Example POST request to create a campaign
      // Provided example body from your code snippet:
      // {
      //   "organizationId": "673966c18f8ca1c05f9c75cc",
      //   "advertiserId": "6760f1fba699a9066ebdca3f",
      //   "name": "Nexxy",
      //   "status": "active",
      //   "dailyBudget": 100,
      //   "startDate": "2024-01-01T00:00:00Z",
      //   "endDate": "2024-12-31T23:59:59Z"
      // }

      const requestBody = {
        organizationId: organizationId, // from params
        advertiserId: "6760f1fba699a9066ebdca3f", // or get from context/selection
        name: name,
        status: "active",
        dailyBudget: Number(dailyBudget),
        startDate: startDate,
        endDate: endDate,
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/campaign/create`,
        requestBody,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Show success
      setSuccessMessage(
        "Campaign created successfully! It may take up to 24 hours for approval."
      );

      // Refresh the list of campaigns
      fetchCampaigns();

      // Clear form
      setName("");
      setDailyBudget("");
      setStartDate("");
      setEndDate("");
    } catch (err) {
      setCreateError(
        err.response?.data?.message ||
          "Failed to create campaign. Please try again."
      );
    } finally {
      setCreating(false);
    }
  };

  if (loading) {
    return (
      <Center minH="100vh">
        <Spinner size="xl" />
      </Center>
    );
  }

  if (error) {
    return (
      <Center>
        <VStack spacing={4}>
          <Heading size="lg" color="red.500">
            Error
          </Heading>
          <Text color={metaColor}>{error}</Text>
          <Button colorScheme="teal" leftIcon={<FiPlus />} onClick={onOpen}>
            Create Campaign
          </Button>
        </VStack>
      </Center>
    );
  }

  return (
    <Box p={{ base: 4, md: 6 }}>
      <Box
        minH="500px"
        mx="auto"
        bg={bgColor}
        borderRadius="lg"
        shadow={cardShadow}
        p={{ base: 4, md: 8 }}
      >
        <HStack
          justify="space-between"
          mb={6}
          flexWrap="wrap"
          spacing={{ base: 2, md: 4 }}
        >
          <Heading as="h1" size={{ base: "md", md: "lg" }} color={textColor}>
            Campaigns Dashboard
          </Heading>
          <Button
            colorScheme="teal"
            size={{ base: "sm", md: "md" }}
            leftIcon={<FiPlus />}
            onClick={onOpen}
          >
            Create New Campaign
          </Button>
        </HStack>

        <Divider mb={6} />

        {campaigns.length === 0 ? (
          <Center>
            <VStack spacing={4}>
              <Text
                fontSize={{ base: "md", md: "lg" }}
                color={metaColor}
                mt={50}
              >
                No campaigns found. Start by creating a new campaign!
              </Text>
            </VStack>
          </Center>
        ) : (
          <SimpleGrid
            columns={{ base: 1, sm: 2, md: 3 }}
            spacing={{ base: 4, md: 6 }}
          >
            {campaigns.map((campaign) => (
              <Box
                key={campaign._id}
                p={{ base: 4, md: 5 }}
                bg={sectionBg}
                borderRadius="md"
                shadow="sm"
                _hover={{ shadow: "lg" }}
                transition="all 0.2s"
              >
                <VStack align="start" spacing={2}>
                  <Heading
                    as="h3"
                    size={{ base: "sm", md: "md" }}
                    color={textColor}
                  >
                    {campaign.name}
                  </Heading>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color={metaColor}
                    noOfLines={2}
                  >
                    DailyBudget ${campaign.dailyBudget}
                  </Text>
                  <Text
                    fontSize={{ base: "xs", md: "sm" }}
                    color={metaColor}
                    noOfLines={2}
                  >
                    SpentToday ${campaign.spentToday}
                  </Text>
                  <HStack>
                    <Badge
                      colorScheme={
                        campaign.status.toLowerCase() === "active"
                          ? "green"
                          : "yellow"
                      }
                    >
                      {campaign.status}
                    </Badge>
                  </HStack>
                  <Button
                    colorScheme="teal"
                    size="sm"
                    mt={3}
                    onClick={() => {
                      navigate(`./${campaign._id}`);
                    }}
                  >
                    View Campaign
                  </Button>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        )}
      </Box>

      {/* Create Campaign Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Campaign</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {successMessage && (
              <Alert status="success" mb={4}>
                <AlertIcon />
                {successMessage}
              </Alert>
            )}
            {createError && (
              <Alert status="error" mb={4}>
                <AlertIcon />
                {createError}
              </Alert>
            )}
            <VStack spacing={4} align="stretch">
              <FormControl>
                <FormLabel>Name</FormLabel>
                <Input
                  placeholder="Campaign name"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Daily Budget</FormLabel>
                <Input
                  type="number"
                  placeholder="100"
                  value={dailyBudget}
                  onChange={(e) => setDailyBudget(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Start Date</FormLabel>
                <Input
                  type="date"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>End Date</FormLabel>
                <Input
                  type="date"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
              </FormControl>
              <Text fontSize="sm" color="gray.500">
                Note: It may take up to 24 hours for your campaign to be
                approved.
              </Text>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button
              colorScheme="teal"
              onClick={handleCreateCampaign}
              isLoading={creating}
              isDisabled={!name || !dailyBudget || !startDate || !endDate}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default CampaignDashboard;
