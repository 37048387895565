import React, { useEffect, useState } from "react";
import {
  Box,
  VStack,
  Heading,
  Text,
  Button,
  useColorModeValue,
  Center,
  Spinner,
  Alert,
  AlertIcon,
  SimpleGrid,
  Avatar,
  HStack,
  Divider,
  Badge,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import axios from "axios";
import Cookies from "js-cookie";
import { FiSend, FiArrowRight, FiUserPlus } from "react-icons/fi";

function OrganizationMembers() {
  const { organizationId } = useParams();
  const [email, setEmail] = useState("");
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [inviting, setInviting] = useState(false);
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  // Theme-specific colors
  const bgColor = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("gray.800", "gray.100");
  const inputBg = useColorModeValue("gray.100", "gray.700");
  const cardShadow = useColorModeValue("lg", "dark-lg");

  const fetchMembers = async (page = 1) => {
    setLoading(true);
    setErrorMessage(null);
    try {
      const token = Cookies.get("AuthToken");
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/v1/organization/${organizationId}/members`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          params: { page, limit: 10 },
        }
      );
      setMembers((prev) =>
        page === 1 ? response.data.members : [...prev, ...response.data.members]
      );
      setHasMore(response.data.hasMore);
    } catch (error) {
      setErrorMessage("Failed to fetch members. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  const handleInvite = async () => {
    setInviting(true);
    setSuccessMessage(null);
    setErrorMessage(null);
    try {
      const token = Cookies.get("AuthToken");
      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/organization/${organizationId}/invite`,
        { email },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSuccessMessage(`Invitation sent to ${email}`);
      setEmail("");
      onClose();
    } catch (error) {
      setErrorMessage(
        error.response?.data?.message || "Failed to send the invitation."
      );
    } finally {
      setInviting(false);
    }
  };

  useEffect(() => {
    fetchMembers();
  }, [organizationId]);

  return (
    <Box
      p={{ base: 4, md: 6 }}
      bgColor={useColorModeValue("gray.100", "gray.900")}
    >
      <Box
        mx="auto"
        bg={bgColor}
        borderRadius="lg"
        shadow={cardShadow}
        p={{ base: 6, md: 8 }}
      >
        <VStack spacing={6} align="stretch">
          <HStack justify="space-between">
            <Heading as="h1" size="lg" color={textColor}>
              Organization Members
            </Heading>
            <Button
              colorScheme="teal"
              leftIcon={<FiUserPlus />}
              onClick={onOpen}
            >
              Invite Member
            </Button>
          </HStack>
          <Text color={textColor} fontSize="sm">
            View and manage your organization's members.
          </Text>

          {successMessage && (
            <Alert status="success" borderRadius="md">
              <AlertIcon />
              {successMessage}
            </Alert>
          )}

          {errorMessage && (
            <Alert status="error" borderRadius="md">
              <AlertIcon />
              {errorMessage}
            </Alert>
          )}

          <Divider />

          <Heading
            as="h2"
            size="md"
            color={textColor}
            mt={6}
            textAlign="center"
          >
            Members List
          </Heading>

          {loading ? (
            <Center>
              <Spinner size="lg" />
            </Center>
          ) : members.length === 0 ? (
            <Text color={textColor} fontSize="sm" textAlign="center">
              No members found. Start by inviting someone!
            </Text>
          ) : (
            <VStack spacing={4} align="stretch">
              {members.map((member) => (
                <HStack
                  key={member.id}
                  bg={inputBg}
                  p={4}
                  borderRadius="md"
                  shadow="sm"
                  align="center"
                >
                  <Avatar name={member.name} src={member.avatar} />
                  <VStack align="start" spacing={1}>
                    <Text fontWeight="bold" color={textColor}>
                      {member.name}
                    </Text>
                    <Text fontSize="sm" color={textColor}>
                      {member.email}
                    </Text>
                  </VStack>
                  <Badge colorScheme="teal" ml="auto">
                    {member.role || "Member"}
                  </Badge>
                </HStack>
              ))}
            </VStack>
          )}

          {hasMore && (
            <HStack justify="center" mt={4}>
              <Button
                size="lg"
                colorScheme="teal"
                rightIcon={<FiArrowRight />}
                onClick={() => {
                  setCurrentPage((prev) => prev + 1);
                  fetchMembers(currentPage + 1);
                }}
                isLoading={loading}
              >
                Next
              </Button>
            </HStack>
          )}
        </VStack>
      </Box>

      {/* Invite Member Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Invite Member</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text mb={4}>
              Enter the email address of the person you'd like to invite:
            </Text>
            <Input
              placeholder="Enter email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              bg={inputBg}
              size="lg"
              focusBorderColor="teal.400"
            />
          </ModalBody>
          <ModalFooter>
            <Button
              colorScheme="teal"
              mr={3}
              isLoading={inviting}
              onClick={handleInvite}
              isDisabled={!email || inviting}
            >
              Send Invite
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default OrganizationMembers;
