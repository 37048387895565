import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  ButtonGroup,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  Divider,
  useToast,
  Checkbox,
  Text,
  Select,
} from "@chakra-ui/react";
import axios from "axios";
import Cookies from "js-cookie";

function Onboarding({ isOpen, onClose, organizationId, refreshData }) {
  const toast = useToast();

  // Keep track of which type of Stripe account the user wants
  const [accountType, setAccountType] = useState("business");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isTosAccepted, setIsTosAccepted] = useState(false);

  // Master form data, covering both business & individual use cases
  const [formData, setFormData] = useState({
    // Common Fields
    business_profile: {
      mcc: "",
      url: "",
    },
    external_account: {
      object: "bank_account",
      country: "US",
      currency: "usd",
      account_holder_name: "",
      account_holder_type: "company", // or "individual"
      routing_number: "",
      account_number: "",
    },
    tos_acceptance: {
      date: 0,
      ip: "",
    },

    // Business-Specific Fields
    company: {
      tax_id: "",
      phone: "",
      owners_provided: true,
      address: {
        line1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "US",
      },
      representative: {
        first_name: "",
        last_name: "",
        dob: {
          day: "",
          month: "",
          year: "",
        },
        address: {
          line1: "",
          city: "",
          state: "",
          postal_code: "",
          country: "US",
        },
        phone: "",
        email: "",
        id_number: "",
        relationship: {
          title: "",
        },
      },
    },

    // Individual-Specific Fields
    individual: {
      first_name: "",
      last_name: "",
      dob: {
        day: "",
        month: "",
        year: "",
      },
      address: {
        line1: "",
        city: "",
        state: "",
        postal_code: "",
        country: "US",
      },
      phone: "",
      email: "",
      id_number: "", // <-- ADDED HERE
    },
  });

  /**
   * On component mount, set TOS acceptance
   * fields in the background (timestamp & IP).
   */
  useEffect(() => {
    // Example: We'll just set the date to now and IP to a dummy value.
    setFormData((prev) => ({
      ...prev,
      tos_acceptance: {
        date: Math.floor(Date.now() / 1000),
        ip: "127.0.0.1",
      },
    }));
  }, []);

  const MCC_OPTIONS = [
    { value: "4812", label: "Telecom Equipment (4812)" },
    { value: "4814", label: "Telecom Services (4814)" },
    { value: "5732", label: "Electronics Stores (5732)" },
    { value: "5734", label: "Computer Software Stores (5734)" },
    { value: "5812", label: "Eating Places & Restaurants (5812)" },
    { value: "5813", label: "Drinking Places - Alcoholic Beverages (5813)" },
    { value: "5999", label: "Miscellaneous & Specialty Retail Shops (5999)" },
    { value: "7372", label: "Computer Programming & Data Processing (7372)" },
    {
      value: "7392",
      label: "Consulting, Management, & Public Relations (7392)",
    },
    {
      value: "7999",
      label: "Recreation Services (Not Elsewhere Classified) (7999)",
    },
    { value: "8011", label: "Doctors & Physicians (8011)" },
    { value: "8021", label: "Dentists & Orthodontists (8021)" },
    { value: "8043", label: "Optometrists & Ophthalmologists (8043)" },
    { value: "8211", label: "Elementary & Secondary Schools (8211)" },
    { value: "8299", label: "Schools & Educational Services (8299)" },
    {
      value: "8398",
      label: "Organizations, Charitable & Social Services (8398)",
    },
    {
      value: "8999",
      label: "Professional Services (Not Elsewhere Classified) (8999)",
    },
  ];

  /**
   * For updating nested fields, e.g. "company.representative.first_name"
   */
  const handleFormChange = (path, value) => {
    const keys = path.split(".");
    setFormData((prev) => {
      const updated = { ...prev };
      let current = updated;
      for (let i = 0; i < keys.length - 1; i++) {
        current[keys[i]] = { ...current[keys[i]] };
        current = current[keys[i]];
      }
      current[keys[keys.length - 1]] = value;
      return updated;
    });
  };

  /**
   * Submit the final data to the server
   */
  const handleSubmit = async () => {
    if (!isTosAccepted) {
      toast({
        title: "Terms of Service not accepted",
        description: "You must accept the Terms of Service to continue.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setIsSubmitting(true);
    try {
      const token = Cookies.get("AuthToken");

      // Build the final "updates" payload
      const finalUpdates = {
        business_profile: {
          mcc: formData.business_profile.mcc,
          url: formData.business_profile.url,
        },
        external_account: { ...formData.external_account },
        tos_acceptance: { ...formData.tos_acceptance },
      };

      if (accountType === "business") {
        finalUpdates.company = { ...formData.company };
        finalUpdates.external_account.account_holder_type = "company";
      } else {
        finalUpdates.individual = { ...formData.individual };
        finalUpdates.external_account.account_holder_type = "individual";
      }

      await axios.post(
        `${process.env.REACT_APP_SERVER}/v1/organization/onboarding/${organizationId}`,
        { updates: finalUpdates },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast({
        title: "Onboarding Completed",
        description: "Your organization has been successfully onboarded.",
        status: "success",
        duration: 3000,
        isClosable: true,
      });
      refreshData();
      onClose();
    } catch (error) {
      console.error("Error completing onboarding:", error);
      toast({
        title: "Onboarding Failed",
        description: error.response?.data?.message || "An error occurred.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // ────────────────────────────────────────────────────────────────────────────────
  // RENDER HELPERS
  // ────────────────────────────────────────────────────────────────────────────────

  const renderBusinessProfile = () => {
    return (
      <VStack spacing={4} align="stretch">
        <FormControl>
          <FormLabel>MCC Code</FormLabel>
          <Select
            placeholder="Select MCC Code"
            value={formData.business_profile.mcc}
            onChange={(e) =>
              handleFormChange("business_profile.mcc", e.target.value)
            }
          >
            {MCC_OPTIONS.map((mcc) => (
              <option key={mcc.value} value={mcc.value}>
                {mcc.label}
              </option>
            ))}
          </Select>
        </FormControl>

        <FormControl>
          <FormLabel>Business Website</FormLabel>
          <Input
            placeholder="https://example.org"
            value={formData.business_profile.url}
            onChange={(e) =>
              handleFormChange("business_profile.url", e.target.value)
            }
          />
        </FormControl>
      </VStack>
    );
  };

  const renderBusinessFields = () => (
    <VStack spacing={4} align="stretch">
      {/* Tax ID, phone, address */}
      <FormControl>
        <FormLabel>Tax ID</FormLabel>
        <Input
          placeholder="e.g. 123456789"
          value={formData.company.tax_id}
          onChange={(e) => handleFormChange("company.tax_id", e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Business Phone</FormLabel>
        <Input
          placeholder="+1 (555) 555-5555"
          value={formData.company.phone}
          onChange={(e) => handleFormChange("company.phone", e.target.value)}
        />
      </FormControl>

      <FormControl>
        <FormLabel>Street Address</FormLabel>
        <Input
          placeholder="Line 1"
          value={formData.company.address.line1}
          onChange={(e) =>
            handleFormChange("company.address.line1", e.target.value)
          }
        />
      </FormControl>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input
            placeholder="City"
            value={formData.company.address.city}
            onChange={(e) =>
              handleFormChange("company.address.city", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Input
            placeholder="State"
            value={formData.company.address.state}
            onChange={(e) =>
              handleFormChange("company.address.state", e.target.value)
            }
          />
        </FormControl>
      </HStack>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input
            placeholder="Zip"
            value={formData.company.address.postal_code}
            onChange={(e) =>
              handleFormChange("company.address.postal_code", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Input
            placeholder="US"
            value={formData.company.address.country}
            onChange={(e) =>
              handleFormChange("company.address.country", e.target.value)
            }
          />
        </FormControl>
      </HStack>

      <Checkbox
        isChecked={formData.company.owners_provided}
        onChange={(e) =>
          handleFormChange("company.owners_provided", e.target.checked)
        }
      >
        Owners Provided
      </Checkbox>

      <Text fontWeight="semibold" mt={4}>
        Representative
      </Text>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder="First Name"
            value={formData.company.representative.first_name}
            onChange={(e) =>
              handleFormChange(
                "company.representative.first_name",
                e.target.value
              )
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder="Last Name"
            value={formData.company.representative.last_name}
            onChange={(e) =>
              handleFormChange(
                "company.representative.last_name",
                e.target.value
              )
            }
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Title</FormLabel>
        <Input
          placeholder="CEO, CFO, etc."
          value={formData.company.representative.relationship.title}
          onChange={(e) =>
            handleFormChange(
              "company.representative.relationship.title",
              e.target.value
            )
          }
        />
      </FormControl>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Day of Birth</FormLabel>
          <Input
            placeholder="DD"
            type="number"
            value={formData.company.representative.dob.day}
            onChange={(e) =>
              handleFormChange("company.representative.dob.day", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Month of Birth</FormLabel>
          <Input
            placeholder="MM"
            type="number"
            value={formData.company.representative.dob.month}
            onChange={(e) =>
              handleFormChange(
                "company.representative.dob.month",
                e.target.value
              )
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Year of Birth</FormLabel>
          <Input
            placeholder="YYYY"
            type="number"
            value={formData.company.representative.dob.year}
            onChange={(e) =>
              handleFormChange(
                "company.representative.dob.year",
                e.target.value
              )
            }
          />
        </FormControl>
      </HStack>

      <Text fontWeight="semibold" mt={4}>
        Representative Address
      </Text>
      <FormControl>
        <FormLabel>Street Address</FormLabel>
        <Input
          placeholder="Line 1"
          value={formData.company.representative.address.line1}
          onChange={(e) =>
            handleFormChange(
              "company.representative.address.line1",
              e.target.value
            )
          }
        />
      </FormControl>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input
            placeholder="City"
            value={formData.company.representative.address.city}
            onChange={(e) =>
              handleFormChange(
                "company.representative.address.city",
                e.target.value
              )
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Input
            placeholder="State"
            value={formData.company.representative.address.state}
            onChange={(e) =>
              handleFormChange(
                "company.representative.address.state",
                e.target.value
              )
            }
          />
        </FormControl>
      </HStack>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input
            placeholder="Zip"
            value={formData.company.representative.address.postal_code}
            onChange={(e) =>
              handleFormChange(
                "company.representative.address.postal_code",
                e.target.value
              )
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Input
            placeholder="US"
            value={formData.company.representative.address.country}
            onChange={(e) =>
              handleFormChange(
                "company.representative.address.country",
                e.target.value
              )
            }
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Representative Phone</FormLabel>
        <Input
          placeholder="+1 (555) 555-5555"
          value={formData.company.representative.phone}
          onChange={(e) =>
            handleFormChange("company.representative.phone", e.target.value)
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Representative Email</FormLabel>
        <Input
          placeholder="example@domain.com"
          value={formData.company.representative.email}
          onChange={(e) =>
            handleFormChange("company.representative.email", e.target.value)
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Last 4 of SSN</FormLabel>
        <Input
          placeholder="XXXX"
          value={formData.company.representative.ssn_last_4}
          onChange={(e) =>
            handleFormChange(
              "company.representative.ssn_last_4",
              e.target.value
            )
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>ID Number</FormLabel>
        <Input
          placeholder="000000000"
          value={formData.company.representative.id_number}
          onChange={(e) =>
            handleFormChange("company.representative.id_number", e.target.value)
          }
        />
      </FormControl>
    </VStack>
  );

  const renderIndividualFields = () => (
    <VStack spacing={4} align="stretch">
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>First Name</FormLabel>
          <Input
            placeholder="First Name"
            value={formData.individual.first_name}
            onChange={(e) =>
              handleFormChange("individual.first_name", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            placeholder="Last Name"
            value={formData.individual.last_name}
            onChange={(e) =>
              handleFormChange("individual.last_name", e.target.value)
            }
          />
        </FormControl>
      </HStack>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Day of Birth</FormLabel>
          <Input
            placeholder="DD"
            type="number"
            value={formData.individual.dob.day}
            onChange={(e) =>
              handleFormChange("individual.dob.day", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Month of Birth</FormLabel>
          <Input
            placeholder="MM"
            type="number"
            value={formData.individual.dob.month}
            onChange={(e) =>
              handleFormChange("individual.dob.month", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Year of Birth</FormLabel>
          <Input
            placeholder="YYYY"
            type="number"
            value={formData.individual.dob.year}
            onChange={(e) =>
              handleFormChange("individual.dob.year", e.target.value)
            }
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Street Address</FormLabel>
        <Input
          placeholder="Line 1"
          value={formData.individual.address.line1}
          onChange={(e) =>
            handleFormChange("individual.address.line1", e.target.value)
          }
        />
      </FormControl>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>City</FormLabel>
          <Input
            placeholder="City"
            value={formData.individual.address.city}
            onChange={(e) =>
              handleFormChange("individual.address.city", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>State</FormLabel>
          <Input
            placeholder="State"
            value={formData.individual.address.state}
            onChange={(e) =>
              handleFormChange("individual.address.state", e.target.value)
            }
          />
        </FormControl>
      </HStack>
      <HStack spacing={4}>
        <FormControl>
          <FormLabel>Postal Code</FormLabel>
          <Input
            placeholder="Zip"
            value={formData.individual.address.postal_code}
            onChange={(e) =>
              handleFormChange("individual.address.postal_code", e.target.value)
            }
          />
        </FormControl>
        <FormControl>
          <FormLabel>Country</FormLabel>
          <Input
            placeholder="US"
            value={formData.individual.address.country}
            onChange={(e) =>
              handleFormChange("individual.address.country", e.target.value)
            }
          />
        </FormControl>
      </HStack>
      <FormControl>
        <FormLabel>Phone</FormLabel>
        <Input
          placeholder="+1 (555) 555-5555"
          value={formData.individual.phone}
          onChange={(e) => handleFormChange("individual.phone", e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Email</FormLabel>
        <Input
          placeholder="example@domain.com"
          value={formData.individual.email}
          onChange={(e) => handleFormChange("individual.email", e.target.value)}
        />
      </FormControl>
      <FormControl>
        <FormLabel>Last 4 of SSN</FormLabel>
        <Input
          placeholder="XXXX"
          value={formData.individual.ssn_last_4}
          onChange={(e) =>
            handleFormChange("individual.ssn_last_4", e.target.value)
          }
        />
      </FormControl>
      {/* ADDED ID NUMBER FOR INDIVIDUAL */}
      <FormControl>
        <FormLabel>ID Number</FormLabel>
        <Input
          placeholder="000000000"
          value={formData.individual.id_number}
          onChange={(e) =>
            handleFormChange("individual.id_number", e.target.value)
          }
        />
      </FormControl>
    </VStack>
  );

  const renderBankFields = () => (
    <VStack spacing={4} align="stretch">
      <FormControl>
        <FormLabel>Account Holder Name</FormLabel>
        <Input
          placeholder="Nexxy LLC or Your Name"
          value={formData.external_account.account_holder_name}
          onChange={(e) =>
            handleFormChange(
              "external_account.account_holder_name",
              e.target.value
            )
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Routing Number</FormLabel>
        <Input
          placeholder="110000000"
          value={formData.external_account.routing_number}
          onChange={(e) =>
            handleFormChange("external_account.routing_number", e.target.value)
          }
        />
      </FormControl>
      <FormControl>
        <FormLabel>Account Number</FormLabel>
        <Input
          placeholder="000123456789"
          value={formData.external_account.account_number}
          onChange={(e) =>
            handleFormChange("external_account.account_number", e.target.value)
          }
        />
      </FormControl>
    </VStack>
  );

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size="xl"
      scrollBehavior="inside"
    >
      <ModalOverlay />
      <ModalContent
        w={{ base: "95%", md: "600px" }}
        maxW="1000px"
        maxH={{ base: "90vh", md: "auto" }}
      >
        <ModalHeader>Complete Onboarding</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {/* Toggle for Account Type */}
          <ButtonGroup mb={4} isAttached>
            <Button
              colorScheme={accountType === "business" ? "teal" : "gray"}
              onClick={() => setAccountType("business")}
            >
              Business
            </Button>
            <Button
              colorScheme={accountType === "individual" ? "teal" : "gray"}
              onClick={() => setAccountType("individual")}
            >
              Individual
            </Button>
          </ButtonGroup>

          {/* Business Profile (MCC, URL) */}
          <Text fontWeight="bold" mb={2}>
            Business Profile
          </Text>
          {renderBusinessProfile()}
          <Divider my={4} />

          {/* Conditional Fields: Business vs. Individual */}
          {accountType === "business" ? (
            <>
              <Text fontWeight="bold" mt={4}>
                Business Information
              </Text>
              {renderBusinessFields()}
            </>
          ) : (
            <>
              <Text fontWeight="bold" mt={4}>
                Individual Information
              </Text>
              {renderIndividualFields()}
            </>
          )}
          <Divider my={4} />

          {/* Bank Account Fields */}
          <Text fontWeight="bold" mt={4}>
            Bank Account Information
          </Text>
          {renderBankFields()}
          <Divider my={4} />

          {/* Terms of Service - single checkbox */}
          <Checkbox
            isChecked={isTosAccepted}
            onChange={(e) => setIsTosAccepted(e.target.checked)}
            colorScheme="teal"
          >
            I have read and agree to the Terms of Service.
          </Checkbox>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="teal"
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={!isTosAccepted}
          >
            Submit
          </Button>
          <Button
            ml={3}
            onClick={onClose}
            variant="ghost"
            isDisabled={isSubmitting}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default Onboarding;
