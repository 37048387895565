import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import {
  Box,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  Center,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";

function CampaignDetails() {
  const { id } = useParams(); // gets the :id from the URL
  const [campaign, setCampaign] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchCampaign = async () => {
      try {
        // Retrieve token from local storage or your preferred auth store
        const token = localStorage.getItem("token");

        // Fetch the campaign by ID
        /*
        const response = await axios.get( 
          `${process.env.REACT_APP_SERVER}/v1/campaign/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        // Assuming response.data contains { campaign: {...} }
        setCampaign(response.data.campaign);
        */
        setLoading(false);
      } catch (err) {
        //   console.error("Error fetching campaign:", err);
        //   setError("Unable to load campaign. Please try again.");
      } finally {
        setLoading(false);
      }
    };

    fetchCampaign();
  }, [id]);

  // Loading state
  if (loading) {
    return (
      <Center mt={10}>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="teal.500"
          size="xl"
        />
      </Center>
    );
  }

  // Error state
  if (error) {
    return (
      <Box p={4}>
        <Alert status="error" borderRadius="md">
          <AlertIcon />
          {error}
        </Alert>
      </Box>
    );
  }

  // No campaign found
  if (!campaign) {
    return (
      <Box p={4}>
        <Alert status="warning" borderRadius="md">
          <AlertIcon />
          No campaign found with ID: {id}
        </Alert>
      </Box>
    );
  }

  // Show the campaign details with Tabs
  return (
    <Box p={4}>
      <Tabs variant="enclosed">
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Donations</Tab>
          <Tab>Updates</Tab>
        </TabList>

        <TabPanels>
          {/* Overview Tab */}
          <TabPanel>
            <Box
              maxW="md"
              borderWidth="1px"
              borderRadius="lg"
              overflow="hidden"
              p={4}
              boxShadow="sm"
            >
              <Heading size="md" mb={2}>
                {campaign.title}
              </Heading>
              <Text fontWeight="bold">ID:</Text>
              <Text mb={2}>{campaign._id}</Text>

              <Text fontWeight="bold">Description:</Text>
              <Text mb={2}>{campaign.description}</Text>

              {/* Add more fields if your campaign has them */}
            </Box>
          </TabPanel>

          {/* Donations Tab (example) */}
          <TabPanel>
            <Heading size="md" mb={4}>
              Donations
            </Heading>
            {/* Here you can render a list of donations or related info */}
            <Text>(Donations content goes here.)</Text>
          </TabPanel>

          {/* Updates Tab (example) */}
          <TabPanel>
            <Heading size="md" mb={4}>
              Updates
            </Heading>
            {/* You can render any updates or announcements here */}
            <Text>(Updates content goes here.)</Text>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default CampaignDetails;
