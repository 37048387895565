import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Auth/Login";
import Signup from "./pages/Auth/Signup";
import Notfound from "./pages/Notfound";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import PasswordReset from "./pages/Auth/PasswordReset";
import AllOrganizationDashboard from "./pages/company/AllOrganizationDashboard";
import CompanyDashboard from "./pages/company/companyDashboard";
import { PublicLayout, PrivateLayout } from "./template/layouts";

// Import Stripe and Elements
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import PrivateRoute from "./components/settings/PrivateRoute";
// Import ChakraProvider and Navbar
import { ChakraProvider } from "@chakra-ui/react";
import Details from "./pages/details";
import TermsOfService from "./pages/legal/tos";
import PrivacyPolicy from "./pages/legal/privacy";
import Profile from "./pages/profile/profile";
import AppDashboard from "./pages/apps/appDashboard";
import CreateOrganization from "./pages/company/createOrganization";
import CreateApp from "./pages/apps/createApp";
import EarlyAccess from "./pages/Auth/earlyAccess";
import CampaignDashboard from "./pages/campaigns/CampaignDashboard";
import OrganizationMembers from "./pages/company/OrganizationMembers";
import BillingPage from "./pages/company/BillingPage";
import SettingsPage from "./pages/company/SettingsPage";
import CampaignDetails from "./pages/campaigns/CampaignDetails";
// Load Stripe with your publishable key
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function App() {
  return (
    <ChakraProvider>
      <BrowserRouter>
        <Routes>
          {/* Public Routes */}
          <Route path="/" element={<PublicLayout />}>
            <Route index element={<Details />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/password-reset" element={<PasswordReset />} />
            <Route path="/early-access" element={<EarlyAccess />} />
            <Route path="/terms" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="*" element={<Notfound />} />
          </Route>

          {/* Private Routes */}
          <Route path="/" element={<PrivateLayout />}>
            <Route
              path="/dashboard"
              element={<PrivateRoute element={<AllOrganizationDashboard />} />}
            />
            <Route
              path="/profile"
              element={<PrivateRoute element={<Profile />} />}
            />
            <Route
              path="/organization/:organizationId"
              element={<PrivateRoute element={<CompanyDashboard />} />}
            />
            <Route
              path="/organization/:organizationId/apps"
              element={<PrivateRoute element={<AppDashboard />} />}
            />
            <Route
              path="/organization/:organizationId/campaigns"
              element={<PrivateRoute element={<CampaignDashboard />} />}
            />
            <Route
              path="/organization/:organizationId/campaigns/:campaignId"
              element={<PrivateRoute element={<CampaignDetails />} />}
            />
            <Route
              path="/organization/:organizationId/members"
              element={<PrivateRoute element={<OrganizationMembers />} />}
            />
            <Route
              path="/organization/:organizationId/billing"
              element={<PrivateRoute element={<BillingPage />} />}
            />
            <Route
              path="/organization/:organizationId/settings"
              element={<PrivateRoute element={<SettingsPage />} />}
            />
            <Route
              path="/organization/:organizationId/apps/create"
              element={
                <PrivateRoute
                  element={
                    <Elements stripe={stripePromise}>
                      <CreateApp />
                    </Elements>
                  }
                />
              }
            />
            <Route
              path="/organizations/create-organization"
              element={
                <PrivateRoute
                  element={
                    <Elements stripe={stripePromise}>
                      <CreateOrganization />
                    </Elements>
                  }
                />
              }
            />
          </Route>
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
